.courselistrepetitions {

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .introWrapper {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid $lightGreyTwo;
  }

  .introText {
    text-align: center;
    font-weight: 400;
    color: $greyishBrown;
    //padding: 5px;
    //margin-bottom: 5px;
  }

  .introText2 {
    text-align: center;
    font-weight: 400;
    color: $tealish;
    //padding:20px;
  }

  .repetitionItems {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .description {
    padding: 0;
    margin-bottom: 0;
    text-align: left;
    font-weight: 300;
    color: $greyishBrown
  }
}