.listsgenerator {
  .selectlevel {


    &.contentWrapper {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start
    }

    .offerToPass {
      font-weight: 700;
      margin: 25px 15px;
      color: $tealish;
      text-align: center
    }

    .levelsWrapper {
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start
    }

    .levelsWrapperRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .levelItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 200px;
      height: 200px;
      border-radius: 10px;
      //background-color: $white;

      img {
        transition: all 0.3s ease-out;

        &:hover {
          filter: grayscale(100%);
        }
      }
    }

    .itemImage1 {
      width: 120px;
      //height: 100px;

      margin-top: 10px;
      margin-bottom: 5px;
    }

    .itemImage {
      width: 120px;
      //height: 100px;

      margin-top: 10px;
      margin-bottom: 5px;
    }


    .levelText {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      color: $tealish;
    }

    .levelNumbers {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: $greyishBrown;
      margin-bottom: 10px;
    }

  }

  .selectwords {

    &.contentWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start
    }

    .howManyLists {
      font-weight: 400;
      margin-bottom: 15px;
      margin-top: 15px;
      color: $greyishBrown;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center
    }

    .level {
      font-weight: 700;
      margin-bottom: 15px;
      color: $tealish;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center
    }

    .totalWords {
      font-weight: 400;
      margin: 25px 15px;
      color: $greyishBrown;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center
    }

    .totalWordsRemark {
      margin: 5px 0;
      font-weight: 300;
      font-style: italic;
      color: $greyishBrown;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center
    }

    .wordsWrapper {
      width: deviceWidth -30;
      margin: 10px 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 40px;
    }

    .choiceWrapper {
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .choiceButtonItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $tealish;
      height:  50px;
      border-radius: 25px;
      margin-bottom: 10px;
      width: 150px;

      &.wider {
        width: 200px;
      }
    }

    .choiceButtonItemText {
      font-weight: 400;
      color: $tealish
    }

    .choiceButtonItemOnMy {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 40px;
      margin-top: 10px;
    }

    .choiceButtonOnMyOwn {
      font-weight: 400;
      color: $greyishBrown
    }

  }

}


