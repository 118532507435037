@import "variables.scss";
@import "mixins.scss";

.headerWrapper {
  width: 100%;
  position: relative;
}

.headerImage {
  position: absolute;
  width: 100%;
}

.customNavBar {
  margin-right: 25px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
}

.backButtonWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 20px;
}

.backButton {
  color: $white
}

.titleWrapper {
  display: flex;
  flex-direction: column;
}

.navBarTitle {
  font-weight: 400;
  text-align: right;
  color: $white
}

;

.subtitle {
  font-weight: 300;
  text-align: right;
  color: $white
}

.headerToolbarWrapper {
  height: 40px;
  border-radius: 20px;
  background-color: $lightGrey;
  z-index: 2;
  right: 25px;
  bottom: 0;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly
}

.headerToolbarWrapperCenter {
  background-color: $lightGrey;
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly
}

.toolbarButtonWrapper {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbarButtonWrapperTablet {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbarButton {
  font-size: 25px;
}

.toolbarButtonTablet {
  font-size: 35px;
}

.toolbarButtonNonActive {
  color: $greyishBrown
}

.toolbarButtonDisabled {
  color: $lightGreyTwo
}

.toolbarButtonActive {
  color: $tealish
}

.searchField {
  width: 100%;
  color: $greyishBrown63;
  background-color: $lightGrey;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left
}

.searchFieldTablet {
  width: 100%;
  color: $greyishBrown63;
  background-color: $lightGrey;
  height: 50px;
  border-radius: 25px;
  font-weight: 400;
  text-align: left
}

.searchFieldContainer {
  width: 80%;
}