
.repetitionItems {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //padding: 10px;

  .description {
    text-align: center;
    font-weight: 300;
    margin-top: 2px;
    margin-bottom: 2px;
    color: $greyishBrown;
    padding-bottom: 5px;
  }
}
.listCard {
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-radius: 10px!important;
  background-color: $white;
  position: relative;

  transition: all 0.3s ease-out;

  &:hover {
    cursor: pointer;

    img {
      filter: grayscale(80%);
    }
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: $white;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
  }

  .listContainer {
    background-color: $white;
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: center;
    justify-content: flex-start;
  }

  .wordImage {
    transition: all 0.3s ease-out;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  .listName {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 300;
    text-align: center;
    color: $tealish;
  }

  .scores {
    font-weight: 300;
    color: $pine;
    margin-right: 5px;
  }

  .scoresNum {
    font-weight: 300;
    color: $greyishBrown;
  }

  .createdAt {
    font-weight: 300;
    color: $greyishBrown;
    text-align: center
  }

  .correct {
    font-weight: 300;
  }

  .starsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
    justify-content: center;
  }

  .star {
    font-size: 18px;
    margin-left: 2px;
    margin-right: 2px;
  }

  .lock {
    color: $lightGreyThree;
  }
  
  .dot {
    width: 20px;
    height: 20px;
    margin-left: 4px;
    margin-right: 4px;
    border-radius: 10px;
  }

  .smallDot {
    width: 15px;
    height: 15px;
    border-radius: 15/2
  }

  .tabletDot {
    width: 30px;
    height: 30px;
    border-radius: 30/2
  }

  .tabletDotMiddle {
    width: 25px;
    height: 25px;
    border-radius: 25/2
  }

  .toDelete {
    width: 20px;
    height: 20px;
    border-radius: (20px/2);
    background-color: $coral;
    position: absolute;
    left: 0;
    top: 0;
  }

  .favorite {
    font-size: 20px;
    color: $golden;
    position: absolute;
    //bottom: 16px;
    //left: 0;
    //right: 0;
    //width: 100%;
  }

  .delete {
    font-size: 20px;
    color: $greyishBrown5;
    position: absolute;
    //bottom: 16px;
    //left: 0;
    //right: 0;
    //width: 100%;
  }

  .bars {
    font-size: 20px;
    color: $greyishBrown5;
    position: absolute;
    right: 5px;
    top: 5px;
  }

  .archive {
    font-size: 20px;
    color: $lightGrey;
    position: absolute;
    left: 10px;
    bottom: 26px;
  }

  .tagPlank {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    z-index: 999;
    text-align: center;
  }

  .tagName {
    font-weight: 300;
    text-align: center;
    color: $white;
    display: block;
  }

}
  


