.trainingalert {
  .body {
    padding: 5px 0;

    button {
      &:hover {
        background-color: $lightGrey75;
      }
    }
  }
}