.generatelistcards {
  
  
  .subHeaderWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px 25px;
  }

  .pageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;
    margin-right: 10px;
  }

  .topView {
    margin-top: 15px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .leftPart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 40%;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 10px;
    border: 1px solid $tealish;
  }

  .rightPart {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 60%;
    padding-left: 10px;
    padding-right: 0;
  }

  .image {
    //width: 100%;
  }
  
  .wordText {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 400;
    color: $tealish
  }

  .translationText {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 400;
    color: $greyishBrown
  }
  
  .exampleText {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 300;
    color: $greyishBrown;
    text-align: center;
  }
  
  .switchRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .switchRowColumn {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .switchRowText {
    font-weight: 400;
    color: $greyishBrown
  }

  .switchButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $tealish;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .switchButtonsColumn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $tealish;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 10px;
  }

  .switchButtonText {
    font-weight: 400;
  }
  
  .pagesWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }

  .switchButtonView {
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .pagesRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $tealish;
    border-radius: 8px;
    overflow: hidden;
  }

  .plusView {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .plusViewIcon {  }

  .editButton {
    color: $greyishBrown;
    font-size: 25px;
  }
  
  .buttonText {
    font-weight: 400;
    color: $white;
  }
}