.worddetails {
  min-height: calc(100vh - 200px);

  .tab-content {
    margin-top: 20px;
  }

  .contentWrapper {
    flex: 1;
    margin-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .wordImageWrapper {
    position: relative;
    overflow: hidden;
  }

  .wordText {
    font-weight: 700;
    color: $tealish;
  }

  .transcription {
    font-weight: 300;
    color: $greyishBrown;
    margin-bottom: 15px;
  }

  .mainTranslation {
    font-weight: 400;
    color: $greyishBrown;
    margin-bottom: 10px;
  }

  .association {
    font-weight: 400;
    color: $pine;
    margin-bottom: 0;
    font-style: italic;
  }

  .mainTranslationWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
  }

  .deleteUserImageButton {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 888;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .uploadUserImageButton {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 888;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .deleteUserImage {
    color: $coral;
  }

  .closeButtonWrapper {
    position: absolute;
    right: 5px;
    //top: 3px;
    width: 30px;
    height: 30px;
    color: $greyishBrown;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 555;
  }

  .closeButton {
    color: $coral;
  }
}
