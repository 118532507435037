.notifications {

  .react-datepicker__time-list-item {
    height: 15px!important;
  }
  .subHeaderWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 25px 20px;
  }

  .react-datepicker__input-container {
    input {
      border:none;
      width: 100px;
      text-align: center;
      color: $tealish;
    }
  }

  .pageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 15px;
    margin-right: 15px;
  }

  .notificationRow {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .notificationType {
    font-weight: 700;
    color: $greyishBrown
  }

  .switchRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .timeRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .notifyText {
    font-weight: 300;
    color: $greyishBrown
  }

  .notifyLevel {
    font-weight: 300;
    color: $tealish;
  }

  .notifyTime {
    font-weight: 400;
    color: $tealish;
  }

  .editButton {
    color: $greyishBrown;
  }

  .pickerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 200px;
    height: 200px;
    left: 0;
    right: 0
  }
}


