.wordmaker {
  min-height: calc(100vh - 200px);

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .introWrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }

  .introTitle {
    font-weight: 700;
    color: $tealish;
    margin-top: 10px;
  }
  
  .introText {
    font-weight: 300;
    color: $greyishBrown;
    margin-bottom: 15px;
  }

  .scores {
    color: $greyishBrown;
    font-weight: 300
  }

  .scoresNumber {
    color: $tealish;
    font-weight: 300
  }

  .icon {
    font-size: 25px;
  }

}

