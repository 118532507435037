.auth-logo {
  min-height: calc(100vh - 200px);


  .logo-container {
    margin-top: 70px;
  }

  .password-reset {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;

    .email {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-width: 0;
    }

    .password {
      border-radius: 0;
      border-bottom-width: 0;
    }

    .password-confirm {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

  }
}

