
.trainingsettings{

  .modal-body {
    min-height: 400px;
  }

  .list-group-item {
    padding: 7px 10px;
    text-align: center;
  }

  .valuepicker {
    position: absolute;
    width: 150px;
    right: 0;
    top: 0;
    transform: translate3d(-45px, 40px, 0px);
  }

  .pageContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }
  
  .introText {
    padding-top: 20px;
    padding-bottom: 20px;
    color: $greyishBrown;
    font-weight: 300;
  }

  .labelContainer {
    width: 90%;
    margin-bottom: 20px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .label {
    color: $greyishBrown;
    font-weight: 300;
    margin-right: 10px;
  }

  .inputContainer {
    width: 80%;
    margin-bottom: 20px;
    display: flex;
    position: relative
  }
  
  .inputField {
    color: $greyishBrown63;
    background-color: $lightGrey;
    height: 40px;
    border-radius: 20px;
    font-weight: 400;
    padding-left: 15px;
    padding-right: 50px;
  }

  .messageField {
    color: $greyishBrown63;
    background-color: $lightGrey5;
    font-weight: 400;
    vertical-align: middle;
  }
  
  .messageContainer {
    width: 90%;
    margin-bottom: 20px;
    display: flex;
    position: relative;
    border: 1px solid $lightGreyThree;
  }

}