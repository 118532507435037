.glossaryWords {
  min-height: calc(100vh - 180px);

  .listDataWrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
  }

  .listName {
    color: $greyishBrown;
    font-weight: 700
  }

  .listCapacity {
    color: $tealish;
    font-weight: 400
  }

  .trainingButtonContainer {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .selectedWrapper {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  };

  .selectedLabel {
    color: $tealish;
    font-weight: 400
  }

  .selectedIcon {
    color: $tealish;
  }

  .buttonText {
    font-weight: 400;
    color: $white;
  }
}