.selectword{

  .contentWrapper {
    //flex: 1;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

  .behindImage {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .behindImageText {
    font-weight: 700;
    color: $tealish;
    text-align: center
  }
  
  .answerButtonsWrapper {
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

  .answerButton {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 50px;
    border-radius: 25px;
    margin-bottom: 10px;
  }

  .notSelected {
    border: 1px solid $tealish;
    background-color: $white;
  }

  .correctButton {
    color: $white;
    border-width: 0;

  }

  .wrongButton {
    color: $white;
    border-width: 0
  }

}

