@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.home {
  min-height: calc(100vh - 130px);

  .homeListHeaderGradient {
    border-radius: 25px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .rightIcons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .scoresWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .userAssetsRow {
    margin: 10px 15px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .calendarIcon {
    color: $pine
  }

  .medalIcon {
    color: $greyishBrown;
    margin-bottom: -5px;
  }

  .newsIcon {
    color: $coral;
    margin-bottom: -5px;
  }

  .scoresText {
    margin-right: 5px;
    color: $greyishBrown;
    font-weight: 300;
  }
  
  .scores {
    color: $tealish;
  }

  .toolbar {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    z-index: 444444;
    justify-content: space-around;
  }

  .toolbarItem {
    color: $greyishBrown;
    font-weight: 700;
  }
  
  .courseItem {
    color: $greyishBrown;
    font-weight: 700;
  }

  .menuButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menuButton1000 {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .roundButton1000 {
    position: absolute;
    background-color: $greyishTeal;
    border-width: 3px;
    border-style: solid;
    border-color: $lightGrey;
    width: 70px;
    height: 70px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toolbarItem100 {
    color: $white;
    font-weight: 700;
  }

  .innerWrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  .angleWrapper {
    width: 100px;
    height: 40px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .homeListHeaderView {
    width: 100%;
    margin-top: 5px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-left: 15px;
    padding-right: 15px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-width: 1px;
    border-style: solid;
  }

  .homeListHeaderText {
    font-weight: 300;
  }
  
  .homeListInfoButton {
    font-size: 15px;
  }

  .courseIcon {
    color: $coral;
    position: absolute;
  }

  .mistakesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    //margin: 10px auto;
    padding: 10px;
    border-radius: 10px;
    background-color: $white;
    &:hover {
      cursor: pointer;
    }
  }
  
  .mistakesImage {
    //width: 138px;
    height: 93px;
    margin-top:15px;
    margin-bottom: 10px;
  }

  .mistakesTitle {
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
    color: $greyishBrown
  }
  
  .mistakesDescription {
    font-weight: 300;
    padding-left: 15px;
    padding-right: 15px;
    color: $greyishBrown
  }
  
  .wordsMistakes {
    font-weight: 700;
    color: $dustyOrange
  }

  .dot {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
  
  .smallDot {
    width: 15px;
    height: 15px;
    border-radius: 15px/2
  }
  
  .tabletDot {
    width: 30px;
    height: 30px;
    border-radius: 30px/2;
  }
  
  .tabletDotMiddle {
    width: 25px;
    height: 25px;
    border-radius: 25px/2;
  }
  
  .listName {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 300;
    text-align: center;
    color: $coral;
    min-height: 40px;
  }

  .scores {
    margin-right: 5px;
  }

  .scoresNum {
    font-weight: 300;
    color: $greyishBrown;
  }

  .promoContainer {
    border-width: 1px;
    border-style: solid;
    border-color: $greyishTeal;
    background-color: $greyishTeal;
    border-radius: 8px;
    padding: 10px 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .promo {
    text-align: center;
    font-weight: 400;
    color: $white;
  }

  .promoDays {
    text-align: center;
    font-weight: 400;
    color: $white;
  }

  .everydays {

    max-width: 400px;
    margin: 15px auto;

    .everyday {
      width: 100%;
    }
  }

  .newUserInfo {
    max-width: 700px;
    margin: 0 auto 20px;
    text-align: center;
    white-space: pre-line;


    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      color: $coral;
    }
  }
}