.addwordmodal {
  .modalWindow {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .introWrapper {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid $lightGreyTwo;
  }

  .introText {
    text-align: center;
    font-weight: 400;
    color: $tealish
  }

  .inSection {
    font-weight: 300;
    color: $greyishBrown
  }

  .addButtonWrapper {
    width: 100%;
    // height: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $lightGreyTwo;
  }

  .addButton {
    font-weight: 400;
    color: $pine
  }

  .buttonText {
    font-weight: 400;
    color: $white;
  }

 .notFound {
    font-weight: 400;
    color: $coral
  }

  .wordText {
    font-weight: 700;
    color: $tealish;
  }

  .transcription {
    font-weight: 300;
    color: $greyishBrown;
  }

  .mainTranslation {
    font-weight: 700;
    color: $greyishBrown;
  }

  .otherTranslation {
    font-weight: 300;
    color: $greyishBrown;
  }

  .trainingButtonContainer {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;

  }
}