.lists {

  min-height: calc(100vh - 200px);

  .deleteButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    text-align: center;
    border-top-width: 1px;
    border-top-color: $lightGreyThree
  }

  .deleteButtonText {
    text-align: center;
    color: $coral;
    font-weight: 700;
  }

  .deleteListsTextWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .deleteListsText {
    color: $greyishBrown;
    font-weight: 400;
    text-align: center
  }

  .selectAllLists {
    font-weight: 400;
    text-align: center
  }

  .deleteButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    border-right-width: 1px;
    border-right-color: $lightGreyThree;
  }

  .trainingButtonContainer {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .preview {
    flex: 1;
    justify-content: flex-end;
    align-items: center;
  }

  .dot {
    width: 20px;
    height: 20px;

    border-radius: 10px;
  }

  .smallDot {
    width: 15px;
    height: 15px;
    border-radius: 15/2
  }

  .tabletDot {
    width: 30px;
    height: 30px;
    border-radius: 30/2
  }

  .tabletDotMiddle {
    width: 25px;
    height: 25px;
    border-radius: 25/2;
  }

  .description {
    text-align: center;
    color: $greyishBrown;
    font-weight: 300;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    font-style: italic;
  }
  
  .bulkModeText {
    color: $greyishBrown;
    font-weight: 400;
    text-align: center
  }

  .selectAllWords {
    font-weight: 400;
    text-align: center
  }
  
  .bulkModeWrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .bulkButton {
    display: flex;
    width: 45%;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-radius: 5px;
  }
  
  .cancelButton {
    display: flex;
    width: 45%;
    justify-content: center;
    align-items: center;
    border: 1px solid $greyishBrown5;
    border-radius: 5px;
  }
  
  .cancelButtonText {
    text-align: center;
    color: $greyishBrown;
    font-weight: 400
  }

  .bulkButtonText {
    text-align: center;
    font-weight: 400
  }
}
