.helper-articles {
  min-height: 500px;

  .articleWrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background-color: $white;

  }

  .articleContainer {
    background-color: $white;
    border-radius: 10px;
    position: relative;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: center;
    justify-content: space-between;
  }

  .articleName {
    font-weight: 700;
    text-align: center;
    color: $tealish;
  }
}
