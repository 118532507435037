.sentencetranslate {

  .dropdown-toggle:after {
    content: none
  }

  .dropdown-link {
    &:hover {
      cursor: pointer;
    }
  }


  .contentWrapper {
    margin-top: 0;
    flex: 1;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .wordButtonsWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .shuffledWordButtonsWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .wordButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;

    margin-left: 2px;
    margin-right: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .shuffledWordButton {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .wordButtonText {
    font-weight: 400;
    text-align: center;
  }

  .answerContainer {
    min-height: 110px;
    margin-bottom: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .percentage {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .wrong-order {
      margin-bottom: 5px;
      font-weight: 300;
    }
  }

  .word {
    padding: 5px 2px;
    //font-weight: 700;
    text-align: center;
    color: $greyishBrown;

    &.fullMatch {
      color: $greenish;
    }

    &.notMatch {
      color: $coral;
    }
  }

  .fullMatch {
    .word {
      color: $greenish;
    }
  }

  .shuffledWordButtonText {
    font-weight: 400;
    text-align: center;
  }

  .sentencesSwitcher {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    left: 0;
    right: 0;
  }

  .sentenceButton {
    margin-left: 5px;
    margin-right: 5px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .sentenceButtonText {
    font-weight: 400;
    text-align: center
  }

  .exampleTranslation {
    font-weight: 300;
    text-align: center;
    display: inline-block;
  }
  
  .allowedMistakes {
    font-weight: 300;
    text-align: left
  }

  .getTranslation {
    font-weight: 300;
    text-align: center;
    color: $waterBlue
  }


}

