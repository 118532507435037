.trainingsettingcell {
  
  .wordContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom: 1px solid $lightGreyThree;
  }
  
 
  .wordWrapper {
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
    align-items: flex-start;
    justify-content: space-around
  }

  .setting {
    font-weight: 400;
  }

  .description {
    font-weight: 300;
    color: $greyishBrown
  }

  .value {
    margin-right: 15px;
    font-weight: 700;
    color: $tealishThree;
    text-align: right;
  }

  .icon {
    color: $tealish;
  }
}


