.wordTranslation {
  .wordContainer {
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: $white;
  }

  .wordWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between
  }

  .wordTranslationWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
  }

  .translation {
    font-weight: 300;
    color: $greyishBrown;
  }

  .makeButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
  }
  
}

