.spelling {
  
  .contentWrapper {
    //flex: 1;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

  .wordImageWrapper {
    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    position: relative;
  }

  .answerButtonSmallWrapper {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    right: -45;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .answerButtonSmall {
    font-size: 30px;
  }

  .behindImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 0;
    right: 0;
  }
  
  .behindImageText {
    font-weight: 700;
    color: $tealish;
    text-align: center;
    display: flex;
    align-items:center;
    justify-content: center;
  }

  .wordImage {
    //border-radius: 5px;
  }

  .inputContainer {
    margin-left: auto;
    margin-right: auto;
    position: relative
  }

  .inputField {
    font-weight: 400;
  }

  .checkButton {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center
  }

  .checkButtonText {
    font-weight: 400;
    //color: $dustyOrange;
    text-align: center;
  }
  
  .association {
    font-weight: 400;
    color: $pine;
    margin-bottom: 0;
    padding-left: 30px;
    padding-right: 30px;
    font-style: italic;
  }

}


