.trainingButtonsModal {
  .buttonsModal {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .introText {
    font-weight: 400;
    font-size: 16px;
  }
  .trainingButtons {
    button {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;

      .buttonText {
        font-weight: 400;
        color: $pine
      }

      .icon {
        position: absolute;
        left: 10px;
        top: 10px;
        //font-size: 20px;
      }
    }
  }
}
