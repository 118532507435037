.multiwordmakergame {
  min-height: calc(100vh - 180px);

  .scores {
    color: $greyishBrown;
    font-weight: 300;
  }

  .scoresNumber {
    color: $tealish;
    font-weight: 300
  }

  .currentWord {
    color: $greyishBrown;
    font-weight: 700
  }

  .allTyped {
    color: $greyishBrown;
    font-weight: 300
  }

  .allTypedWords {
    height: 50px;
    overflow-y: scroll;;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .typedWord {
    color: $tealishThree;
    font-weight: 700
  }

  .wordButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    margin-left: 2px;
    margin-right: 2px;
  }

  .wordButtonText {
    font-weight: 400;
  }

  .shuffledWordButtonsWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  .shuffledWordButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
  }

  .shuffledWordButtonText {
    font-weight: 400;
  }
}


