.vocabularytoolbar {

  .search-wrapper {
    @media (max-width: 550px) {
      width: 100%;
    }
  }
  .vocabulary-input {
    width: 200px;
    @media (max-width: 550px) {
      width: 100%;
    }
  }
}