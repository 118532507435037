.userbadgemodal {

  .modalView {
    background-color: white;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative
  }

  .trainingButtonContainer {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .badgeWrapper {
    width: 150px;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    height: 150px;
    display: flex;
    flex-direction: column;
    margin: 20px auto;
  }

  .background {
    width: 130px;
    top: 10px;
    height: 130px;
    position: absolute;
    border-radius: (130px/2);
    display: flex;
    align-items: center;
    justify-content: center
  }
  
  .imageBadge {
    width: 130px;
    height: 130px;
    border-radius: (130px/2);
  }

  .badgeName {
    padding-left: 20px;
    padding-right: 20px;
    color: $greenish;
    font-weight: 700;
  }

  .segueButton {
    margin-top: 30px;
    width: 200px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: $white;
    border: 1px solid $lightGreyThree;
  }

  .segueName {
    color: $white;
    font-weight: 400;
  }

  .badgeDescription {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    color: $greyishBrown;
    font-weight: 400;
    white-space: pre-line;
  }

  .badgeEarned {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    color: $tealish;
    font-weight: 700;
  }

  .closeButtonWrapper {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    color: $greyishBrown;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .closeButton {
    font-size: 30px;
    color: $greyishBrown63;
  }
  
}