.listWordCard {
  margin: 10px;

  transition: all 0.3s ease-out;

  &:hover {
    cursor: pointer;
    img {
      filter: grayscale(80%);
    }
  }

  .wordImage {
    transition: all 0.3s ease-out;
  }

  .listWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 10px 5px;
    border-radius: 10px;
    background-color: $white;
  }

  .listContainer {
    background-color: $white;
    border-radius: 10px;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: center;
    justify-content: flex-start
  }

  .selectButton {
    padding:10px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  .settingsButton {
    padding:10px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  .word {
    font-weight: 700;
    text-align: center;
    color: $tealish;
  }

  .wordTranslation {
    font-weight: 400;
    text-align: center;
    color: $greyishBrown;
  }

  .example {
    font-weight: 300;
    text-align: center;
    color: $greyishBrown;
  }

  .exampleTranslation {
    font-weight: 300;
    text-align: center;
    color: $greyishBrown63;
  }

  .transcription {
    font-weight: 300;
    text-align: center;
    color: $greyishBrown;
  }

  .starsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin: 10px;
  };

  .star {
    font-size: 18px;
    margin-left: 2px;
    margin-right: 2px;
  };

  .lock {
    font-size: 30px;
    color: $greyishBrown;
  };

  .soundWrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0
  }
}