.wordtest {
  min-height: calc(100vh - 200px);

  .headerWrapper {
    width: 100%;
    height: 100px;
    position: relative;
  }

  .headerImage {
    position: absolute;
    top: -20;
    height: 100px;
    width: 100%;
  }

  .customNavBar {
    height: 40px;
    margin-top: 20px;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: row;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
    z-index: 2;
  }

  .backButtonWrapper {
    height: 40px;
    width: 40px;
  }

  .backButton {
    font-size: 20px;
    color: $white
  }

  .thinkingBoy {
    position: absolute;
    width: 100px;
    bottom: 0;
    right: -25px;
  }

  .contentWrapper {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

  .testWordWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .testWord {
    font-weight: 700;
    color: $greyishBrown;
  }

  .barWrapper {
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .answerButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .answerButtonsWrapper {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 60px;
    margin-right: 60px;
  }

  .answerButton {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .answerButtonText {
    text-align: center;
    font-weight: 400;
  }

  .dontknowButton {
    height: 50px;
    margin-top: 30px;
    font-weight: 400;
    color: $melon;
  }

  .notSelected {
    border-color: $tealish;
    background-color: $white;
    border-width: 1px;
    border-style: solid;
  }

  .correctButton {
    color: $white;
    border-width: 0;
    border-style: solid;
  }

  .wrongButton {
    color: $white;
    border-width: 0;
    border-style: solid;
  }
}