.relaxcell {

  margin-left: 20px;
  margin-right: 20px;

  img {
    transition: all 0.3s ease-out;
  }
  &:hover {
    cursor: pointer;

    img {
      filter: grayscale(80%);
    }
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: $white;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .listContainer {
    background-color: $white;
    border-radius: 10px;
    position: relative;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: center;
    justify-content: flex-start
  }
  
  .wordImage {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .listName {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 700;
    text-align: center;
    color: $tealish;
    white-space: pre-line;
  }

  .description {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 400;
    text-align: center;
    vertical-align: center;
    color: $greyishBrown;
    border-radius: 5px;
  }

}

