.memorycards {
  
  .contentWrapper {
    flex: 1;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

  .circleWrapper {
    position: absolute;
    background-color: $greyishBrown2;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 5000000;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .cardsWrapper {
    height: 100%;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center
  }

  .wordcard {
    width: 190px;
    height: 100px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid $tealish;
    text-align: center;
  }

  .imageCard {
    //width: 190px;
    height: 90px;
  }
  
  .wordCardText {
    color: $tealish;
    text-align: center;
  }

}

