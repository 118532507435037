.copypaste {

  .chars {
    font-weight: 300;
    //position: absolute;
    //bottom: 5px;
    //right: 5px;
  }

  .allWords {
    font-weight: 300;
    margin-left: 5px;
  }
}