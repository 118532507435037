.dragimage {

  .imageWrap {
    &:hover {
      cursor: pointer;
    }
  }
  .contentWrapper {
    flex: 1;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

  }
  
  .wordsWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .wordsRows {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
     justify-content: space-around;
  }
  
  .behindImage {
    // width: deviceHeight > 700 ? 200 : 145;
    // height: deviceHeight > 700 ? 145 : 105;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-color: $lightGrey;
    border-radius: 5px;

  }
  
  .behindImageText {
    font-weight: 700;
    color: $tealish;
    text-align: center;
  }
  
  .wordCard {
    // width: (deviceWidth - 40) / 2;
    // height: deviceHeight > 700 ? ((deviceWidth / 2) - (40 / 2)) / 1.38 : 105;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    // marginHorizontal: 5;
    // marginVertical: 5;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    // borderColor: colors.tealish;
    text-align: center

  }

  .wordCardText {
    font-weight: 400;
    // color: colors.tealish;
    
    text-align: center
  }

  .wordImageWrapper {
    margin-bottom: 15px;
    position: relative;
    z-index: 10;
  }

  .textImageWrapper {
    // width: deviceHeight > 700 ? 200 : 145;
    // height: deviceHeight > 700 ? 145 : 105;
    margin-bottom: 15px;
    position: relative;
    z-index: 10;
  }

  .wordImage {
    position: absolute;
    z-index: 10;
    border-width: 1px;
    border-style: solid;
    border-color: $lightGrey;
    border-radius: 5px;
  }

}

