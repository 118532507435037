.preview {
  .dropdown-toggle:after {
    content: none
  }

  .dropdown-link {
    &:hover {
      cursor: pointer;
    }
  }

  .contentWrapper {
    flex: 1;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  
  .wordImageWrapper {
    background-color: $lightGrey;
  }
  
  .wordText {
    font-weight: 700;
    color: $tealish;
  }
  
  .transcription {
    font-weight: 300;
    color: $greyishBrown;
    margin-bottom: 10px;

  }
  
  .mainTranslation {
    font-weight: 700;
    color: $greyishBrown;
  }
  
  .otherTranslation {
    font-weight: 300;
    color: $greyishBrown;
  }

  .translationsWrapper {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top-color: $lightGreyThree;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-right-color: $lightGreyThree;
    border-left-color: $lightGreyThree;
    justify-content: center;
    z-index: 30;
    background-color: $white
  }

  .translationsButton {
    width: 100%;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chevronUp {
    font-size: 25px;
    color: $tealish;
  }
  
  .translationsHeaderView {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $lightGrey
  }
  
  .translationsHeaderText {
    font-weight: 400;
    color: $greyishBrown;
  }

  .sentenceSlider {
    background-color: $lightGrey25;
  }

  .association {
    font-weight: 400;
    color: $pine;
    margin-bottom: 0;
    padding-left: 30px;
    padding-right: 30px;
    font-style: italic;
  }

  .getTranslation {
    font-weight: 300;
    text-align: center;
    color: $waterBlue;
  }
}



