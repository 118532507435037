.hangman {

  a[disabled] {
    cursor: default;
  }

  .mummyWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
  }
  
  .hangmanImageView {
    // width: 150;
    // height: 150;
    position: relative;
    // elevation: 1
  }
  
  .mummyOverlay {
    position: absolute;
    z-index: 1000;
    background-color: white;
    left: 0;
    right: 0;
    top:0
  }

  .hangmanImage {
    // width: 150;
    // height: 150;
  }

  .wordLettersViewWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // paddingHorizontal: 10;
    // marginVertical: 15
  }
  
  .wordLettersView {
    display: flex;
    // width: 20;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    margin-left: 2px;
    margin-right: 2px;
    align-items: center;
    justify-content: center
  }

  .wordLetter {
    // font-size: 18
  }

  .typedWordView {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    justify-content: center
  }

  .typedWord {
    font-size: 30px;
    font-weight: 700;
    
    color: $tealish
  }

  .correctWordsView {
    display: flex;
    margin-top: 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    align-items: flex-start;
    justify-content: flex-start
  }

  .correctWords {
    font-size: 12px;
    font-weight: 300;
    
    color: $tealish;
    text-align: center
  }

  .lettersWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }

  .letterView {
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2.5px;
    margin-right: 2.5px;
    background-color: white
  }

  .letter {
    font-weight: 400;
  }
  
}

