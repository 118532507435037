.registeralert {

  .icons {
    .itunes {
      margin-right: 20px;

      @media (max-width: 400px) {
        margin-right: 0;
        margin-bottom: 20px;
      }

    }
    @media (max-width: 400px) {
      flex-direction: column;
      margin: 20px;
      align-items: center;
    }
  }

  .desc {
    white-space: pre-line;
    font-size: 1.2rem;
  }
}