.guestnavbar {
  .navbar-dark .navbar-nav .nav-link.active {
    color: #ffffff !important;
    svg {
      color: white !important;
    }
  }

  .navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
    color: #ffffff !important;
    svg {
      color: white !important;
    }
  }

  .nav-link {
    svg {
      color: rgba(255, 255, 255, 0.5);
    }
    &.active {
      svg {
        color: white;
      }
    }

  }

  .dropdown-toggle {
    &:after {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}