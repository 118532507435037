@import "../styles/_variables.scss";

.mnemonic.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}

.nl2br {
  white-space: pre-line;
}

.mnemonic.bg-dark {
  background-image: linear-gradient(#30626b, #386468, #598F8F)!important;
}

.footer.mnemonic.bg-dark {
  background-image: linear-gradient(to top, #30626b, #386468, #598F8F)!important;
}

.footer {
  a {
    text-decoration: underline;
  }
}

.pageWrapper {
  //padding-top: 70px;
  position: relative;
}

button {
  &:disabled {
    &:hover {
      cursor: not-allowed;
    }
  }
}

a svg {
  color: $greyishBrown5;
}

.noConnectionView {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  height: 40px;
  background-color: $coral;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative
}

.noConnectionText {
  font-weight: 300;
  color: $white;
}

.checkConnectionButton {
  font-size: 30px;
  color: $white
}

.labelText {
  font-size: 16px;
  color: $greyishBrown;
  margin-bottom: 5px;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5000000;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: $black;
  opacity: 0.5;
  //elevation: 4
};

.overlay5 {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5000000;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: $greyishBrown;
  opacity: 0.2;
  //elevation: 4;
}

.listWrapper {
  flex: 1;
  margin-bottom: 0
}

.listWrapperNoTop {
  flex: 1;
  margin-bottom: 0
}

.backButtonWrapper {
  height: 40px;
  width: 70px;
}

.backButton {
  font-size: 20px;
  color: $white
}

.navBarTitle {
  height: 40px;
  font-size: 18px;
  font-weight: 700;
  text-align: right;
  color: $white
}

.inputField {
  color: $greyishBrown63;
  background-color: $lightGrey;
  height: 40px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
}

.inputFieldBig {
  color: $greyishBrown63;
  background-color: $lightGrey;
  height: 50px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
}

.inputFieldTablet {
  color: $greyishBrown63;
  background-color: $lightGrey;
  height: 70px;
  border-radius: 70/2;
  font-weight: 400;
  padding-left: 15px;
  padding-right: 15px;
}

.buttonBlue {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 200px;
  border-radius: 25px;
  text-align: center;
  margin-left: auto; margin-right: auto;
}

.buttonTablet {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 400px;
  border-radius: 35px;
  text-align: center;
  margin-left: auto; margin-right: auto;
}

.buttonTabletNoWidth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  border-radius: 40px;
  text-align: center;
  margin-left: auto; margin-right: auto;
}

.buttonBlueWidth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 300px;
  border-radius: 25px;
  text-align: center;
  margin-left: auto; margin-right: auto;
}

.buttonBlueNoWidth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-radius: 25px;
  text-align: center;
  margin-left: auto; margin-right: auto;
}

.buttonBlueNoD {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto; margin-right: auto;
}

.buttonEmpty {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: auto; margin-right: auto;
}

.buttonBlueSmall {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 200px;
  border-radius: 20px;
  text-align: center;
  margin-left: auto; margin-right: auto;
}

.buttonText {
  font-weight: 400;
  color: $white;
}

.buttonTextFontless {
  font-weight: 400;
  //color: $white;
}

.input_field {
  color: $white;
  background-color: transparent;
  border-bottom: 1px solid $white;
  border-radius: 7px;
  padding: 11px;
  font-weight: 400;
  font-size: 16px
}

.button_primary {
  color: $white;
  font-weight: 500;
  background-color: $pink;
  border: 1px solid $pink;
  border-radius: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  align-items: center
}

.activity_indicator {
  background-color: $white;
  flex: 1;
  justify-content: center
}

.divider_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  width: 80%
}

.divider_left {
  background-color: $brightgrey;
  height: 1px;
  width: 40%;
  margin-right: 10px;
}

.divider_right {
  background-color: $brightgrey;
  height: 1px;
  width: 40%;
  margin-left: 10px;
}

.divider_text {
  color: $lightGrey
}

.headerToolbarWrapper {
  height: 40px;
  border-radius: 20px;
  background-color: $lightGrey;
  z-index: 2;
  right: 25px;
  bottom: 0;
  position: absolute
}

.divider {
  width: 100%;
  height: 10px;
  background-color: $lightGrey5
}

.divider2 {
  width: 100%;
  height: 2px;
  background-color: $lightGrey5
}

.topBorder {
  border-top: 1px solid $lightGreyThree;
}

.cancelButton {
  width: 100%;
  height: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $greyishBrown;
  border-top: 1px solid $lightGreyThree;
}

.cancelButtonText {
  font-weight: 400;
  font-size: 14px;
}

.glossaryBorder {
  border: 1px solid $lightGreyThree;
}

.toolbarBorder {
  border-top: 1px solid $lightGreyThree
}

.custom-pagination .pagination {
  margin-bottom: 0!important;
  .page-item.active .page-link {
    background-color: $waterBlue;
    border-color: $waterBlue;
    color: white;
  }
  .page-link {
    color: $waterBlue;
  }
}

.fixed-toolbar {
  transition: all 0.3s ease-out;
}

.fixed-toolbar {
  padding: 5px 25px;
  background-color: white;
  position: fixed;
  top: 50px;
  @media (max-width: 767px) {
    top: 55px;
  }
  right:0;
  left: 0;
  z-index: 1000;
}

.learn-button-container {
  display: flex;
  width: 100%;
  position: fixed;
  bottom: 0;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: white;

}

.toggled-link {
  color: $greyishBrown5;
  transition: all 0.3s ease-out;

  &:after {
    color: $greyishBrown5;

    &:hover {
      cursor: pointer;
      color: $greyishBrown5;
      opacity: 0.8
    }
  }

  &:hover {
    cursor: pointer;
    color: $greyishBrown5;
    opacity: 0.8
  }
}

.on-hover {
  &:hover {
    cursor: pointer;
    opacity: 0.8
  }
}