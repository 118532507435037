.unreadNews {
  position: absolute;
  right: -5px;
  top: -5px;
  color: $coral;
}

@media (max-width: 991px) {
  .navbar-nav .dropdown-menu {
    position: absolute;
    left:0;
  }

  .navbar-collapse.collapse.show {
    overflow-y: auto;
  }
}

.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff!important;
  svg {
    color: white!important;
  }
}

.navbar-dark .navbar-nav .show > .nav-link, .navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff!important;
  svg {
    color: white!important;
  }
}

.nav-link {
  svg {
    color: rgba(255, 255, 255, 0.5);
  }
  &.active {
    svg {
      color: white;
    }
  }

}