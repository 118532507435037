.coursevocabulary {
  min-height: calc(100vh - 200px);

  .trainingButtonContainer {
    padding-top:5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .description {
    font-weight: 300;
    color: $greyishBrown;
    margin: 5px auto 15px;
    text-align: center;
  }

  .deleteListsTextWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .bulkModeText {
    color: $greyishBrown;
    font-weight: 400;
    text-align: center
  }

  .selectAllWords {
    font-weight: 400;
    text-align: center
  }

  .bulkModeWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .bulkButton {
    display: flex;
    width: 45%;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
  }

  .bulkButtonText {
    text-align: center;
    font-weight: 400
  }
}

