.phrase {

  &.wordContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 5px;
    //border-radius: 10px;
    //background-color: $white;
  }

  .trainingButtonContainer {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  
  .wordWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    align-items: center;
    justify-content: space-between
  }
  
  .wordTranslationWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .word {
    font-weight: 400;
    color: $tealish;
    margin-bottom: 5px;
  }

  .translation {
    font-weight: 300;
    color: $greyishBrown
  }

  .rightPartWrapper {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end
  }

  .wordsLabel {
    font-size: 10px;
    font-weight: 400;
    color: $greyishBrown;
    margin-right: 15px;
  }

  .infoButton {
    color: $greyishBrown;
  }

  .angleWrapper {
    display: flex;
    flex-direction: row;
    align-items: center
  }
}


