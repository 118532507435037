.familyaccounts {
  min-height: calc(100vh - 180px);

  .introView {
    //display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .addIcon {
   
  }
  
  .accountsLabel {
    font-weight: 700;
    color: $greyishBrown;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .howDeleteLabel {
    font-weight: 300;
    color: $greyishBrown;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
}

.infoIcon {
  &:hover {
    cursor: pointer;
  }
}
