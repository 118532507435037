.dictionaryWord {

  &:hover {
    cursor: pointer;
  }

  padding: 10px!important;

  .wordContainer {
    display: flex;
    flex-direction: column;
    //margin: 5px 10px;
    //border-radius: 10px;
    background-color: $white;
  }

  .wordWrapper {
    width: 100%;
    //height: 100%;
    display: flex;
    flex-direction: row;
    //padding-top:5px;
    //padding-bottom:5px;
    align-items: center;
    justify-content: space-between
  }
  
  .wordTranslationWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .word {
    font-weight: 400;
    color: $tealish;
    margin-bottom: 5px;
  }
  
  .translation {
    font-weight: 300;
    color: $greyishBrown
  }

  .infoButton {
    color: $greyishBrown;
  }

  .angleWrapper {
    width: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    //height: 100%;
    margin-right: 10px;
  }
}


