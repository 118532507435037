.trainingSubheader {

  .training-name {
    font-size: 22px;
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
  .first-row-block {
    margin-left: -25px;
    margin-right: -25px;

    @media (max-width: 776px) {
      margin-left: -15px;
      margin-right: -15px;
    }

    @media (max-width: 500px) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  .exitBlock {
    float: left;
  }

  .settingsBlock {
    float: right;
  }

  margin-top: 15px;
  margin-bottom: 15px;

  .btn-group {
    button {
      &:first-child {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
      }

      &:last-child {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
      }
    }
  }

  .infoIcon {
    font-size: 25px;
    @media (max-width: 500px) {
      font-size: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .subHeaderWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .leftPart {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }

  .scoresWrapper {
    position: absolute;
    left: 0;
  }

  .scores {
    color: $tealish;
    font-weight: 300
  }

  .rightPart {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
  }

  .controlButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .divider {
    width: 1px;
    background-color: $greyishBrown
  }

  .controlButton {
    color: $greyishBrown;
  }

  .attemptsView {
    position: absolute;
    left: 0;
    // top: 12
  }
  .attemptsText {
    color: $coral;
  }

  .timer {
    font-size: 30px;
    font-weight: 700;
    text-align: left;
  }


}