.listWords {
  min-height: calc(100vh - 180px);

  .trainingButtonContainer {
    /*padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;*/
  }

  .deleteListsTextWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }
  
  .bulkModeText {
    color: $greyishBrown;
    font-weight: 400;
    text-align: center
  }

  .selectAllWords {
    font-weight: 400;
    text-align: center
  }

  .bulkModeWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }

  .bulkButton {
    display: flex;
    width: 45%;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-radius: 5px;
  }

  .cancelButton {
    display: flex;
    width: 45%;
    justify-content: center;
    align-items: center;
    border-width: 1px;
    border-radius: 5px;
    border-color: $greyishBrown5
  }

  .cancelButtonText {
    text-align: center;
    color: $greyishBrown;
    font-weight: 400
  }

  .bulkButtonText {
    text-align: center;
    font-weight: 400
  }
}


