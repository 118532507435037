.userbadges {

  min-height: calc(100vh - 200px);

  .opened {
    text-align: center;
    padding: 0 20px;
    margin: 10px 0;
    color: $tealish
  }

}


