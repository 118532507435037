.listening {

  .contentWrapper {
    flex: 1;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .wordButtonsWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .shuffledWordButtonsWrapper {
    padding: 20px 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .wordButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;

    justify-content: center;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .shuffledWordButton {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    margin-left: 2px;
    margin-right: 2px;
    padding-left: 2px;
    padding-right: 2px;
  }

  .wordButtonText {
    font-weight: 400;
    text-align: center;
  }

  .word {
    font-weight: 700;
    text-align: center;
    color: $tealish
  }

  .shuffledWordButtonText {
    font-weight: 400;
    text-align: center;
  }

  .sentencesSwitcher {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    left: 0;
    right: 0;
  }

  .sentenceButton {
    margin-left: 2px;
    margin-right: 2px;
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }

  .sentenceButtonText {
    font-weight: 400;
    text-align: center
  }

  .example {
    font-weight: 400;
    text-align: center;
    color: $tealish
  }

  .exampleTranslation {
    font-weight: 300;
    text-align: center
  }

  .allowedMistakes {
    font-weight: 300;
    text-align: left
  }

  .getTranslation {
    font-weight: 300;
    text-align: center;
    color: $waterBlue;
  }
}


