
.dFlex {
  display: flex;
}

.flexH {
  display: flex;
  flex-direction: row;
}

.flexHorizontal {
  display: flex;
  flex-direction: row;
}

.fVertical {
  display: flex;
  flex-direction: column;
}

.flexVertical {
  display: flex;
  flex-direction: column;
}

.flexV {
  display: flex;
  flex-direction: column;
}

.flexCenter {
  justify-content: center;
  align-items: center;
}

.flexStart {
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  justify-content: flex-end;
  align-items: center;
}

.flexVStart {
  justify-content: center;
  align-items: flex-start;
}

.flexAround {
  justify-content: space-around;
  align-items: center;
}

.spaceAround {
  justify-content: space-around;
  align-items: center;
}

.spaceBetween {
  justify-content: space-between;
  align-items: center;
}

.w10 {
  width: 10%
}

.w20 {
  width: 20%
}

.w25 {
  width: 25%
}

.w30 {
  width: 30%
}

.w40 {
  width: 40%
}

.w50 {
  width: 50%
}

.w60 {
  width: 60%
}

.w70 {
  width: 70%
}

.w75 {
  width: 75%
}

.w80 {
  width: 80%
}

.w90 {
  width: 90%
}

.w100 {
  width: 100%
}

.mr1 {
  margin-right: 5px;
}

.mr2 {
  margin-right: 10px;
}

.mr3 {
  margin-right: 15px;
}

.mr4 {
  margin-right: 20px;
}

.ml1 {
  margin-left: 5px;
}

.ml2 {
  margin-left: 10px;
}

.ml3 {
  margin-left: 15px;
}

.ml4 {
  margin-left: 20px;
}

.ml5 {
  margin-left: 25px;
}

.mh02 {
  margin-left: 2px;
  margin-right: 2px;
}

.mh03 {
  margin-left: 3px;
  margin-right: 3px;
}

.mh1 {
  margin-left: 5px;
  margin-right: 5px;
}

.mh2 {
  margin-left: 10px;
  margin-right: 10px;
}

.mh3 {
  margin-left: 15px;
  margin-right: 15px;
}

.mh4 {
  margin-left: 20px;
  margin-right: 20px;
}

.mh5 {
  margin-left: 25px;
  margin-right: 25px;
}

.mv1 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.mv2 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.mv3 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.mv4 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mv5 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.mb1 {
  margin-bottom: 5px;
}

.mb2 {
  margin-bottom: 10px;
}

.mb3 {
  margin-bottom: 15px;
}

.mb4 {
  margin-bottom: 20px;
}

.mb5 {
  margin-bottom: 25px;
}

.mt1 {
  margin-top: 5px;
}

.mt2 {
  margin-top: 10px;
}

.mt3 {
  margin-top: 15px;
}

.mt4 {
  margin-top: 20px;
}

.mt5 {
  margin-top: 25px;
}

// PADDING
.p1 {
  padding: 5px;
}

.p2 {
  padding: 10px;
}

.p3 {
  padding: 15px;
}

.p4 {
  padding: 20px;
}

.p5 {
  padding: 25px;
}

.ph1 {
  padding-left: 5px;
  padding-right: 5px;
}

.ph2 {
  padding-left: 10px;
  padding-right: 10px;
}

.ph3 {
  padding-left: 15px;
  padding-right: 15px;
}

.ph4 {
  padding-left: 20px;
  padding-right: 20px;
}

.ph5 {
  padding-left: 25px;
  padding-right: 25px;
}

.pv1 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pv2 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.pv3 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.pv4 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.pv5 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.pb1 {
  padding-bottom: 5px;
}

.pb2 {
  padding-bottom: 10px;
}

.pb3 {
  padding-bottom: 15px;
}

.pb4 {
  padding-bottom: 20px;
}

.pb5 {
  padding-bottom: 25px;
}

.pt1 {
  padding-top: 5px;
}

.pt2 {
  padding-top: 10px;
}

.pt3 {
  padding-top: 15px;
}

.pt4 {
  padding-top: 20px;
}

.pt5 {
  padding-top: 25px;
}

.minH100 {
  min-height: 100px;
}

.minH200 {
  min-height: 200px;
}

.minH250 {
  min-height: 250px;
}

.minH300 {
  min-height: 300px;
}

// FONTS

.fontLight {
  font-weight: 300;
}

.fontNormal {
  font-weight: 400
}

.fontItalic {
  font-weight: 400;
  font-style: italic
}

.fontBold {
  font-weight: 700;
}

.textCenter {
  text-align: center
}

.round15 {
  width: 15px;
  height: 15px;
  border-radius: 15/2;
}

// BACKGROUNDS
.bgTealish {
  background-color: $tealish
}

.bgGreenish {
  background-color: $greenish
}

.bgWaterBlur {
  background-color: $waterBlue
}

.bgCoral {
  background-color: $coral
}

// RADIUS

.r5 {
  border-radius: 5px;
}

.r10 {
  border-radius: 10px;
}

//ICONS
.icon15 {
  font-size: 15px;
}

.icon20 {
  font-size: 20px;
}

.icon25 {
  font-size: 25px;
}

.icon30 {
  font-size: 30px;
}

.icon35 {
  font-size: 35px;
}

.icon40 {
  font-size: 40px;
}

.icon45 {
  font-size: 45px;
}

.overlayWhite {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5000000;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: $white;
  opacity: 0.9;
}

.overlayBlack {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 5000000;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: $black;
  opacity: 0.6;
}
