.courseListWords {
  min-height: calc(100vh - 200px);

  .introWrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .startButton {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .trainingButtonContainer {
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }

}

