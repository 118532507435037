.missingletters {

  .contentWrapper {
    //flex: 1;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

  .wordImageWrapper {
    overflow: visible;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wordImage {
    border-radius: 5px;
  }

  .behindImage {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .behindImageText {
    width: 100%;
    color: $tealish;
    text-align: center
  }

  .wordButtonsWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }

  .shuffledWordButtonsWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }

  .wordButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wordButtonText {
    font-weight: 700;
  }

  .shuffledWordButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 5px;
  }

  .shuffledWordButtonText {
    font-weight: 400;
  }

  .answerButton {
    border-width: 1px;
    border-style: solid;
    min-width: 100px;
  }
}

