.wordSynonym {

  .wordWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 5px;
  }

  .wordTranslationWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
    margin-left: 5px;
    margin-right: 5px;
  }

  .word {
    font-weight: 300;
    color: $tealish;
    margin-bottom: 2px;
  }

  .translation {
    font-weight: 300;
    color: $greyishBrown
  }

  .rightPartWrapper {
    width: 45px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end
  }

  .touchable {
    margin-right: 5px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
  }

  .infoButton {
    color: $greyishBrown5;
    font-size: 15px;
  }
}
  