.wizard {
  min-height: calc(100vh - 130px);

  .thinkingBoy {
    position: absolute;
    width: 100px;
    //height: 368px;
    bottom: 0;
    right: -25px;
  }

  .contentWrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

  .test {
    .customNavBar {
      margin-left: 0;
      margin-right: 0;
      display: flex;
      flex-direction: row;
      text-align: right;
      align-items: center;
      justify-content: flex-end;
      z-index: 2
    }

    .contentWrapper {
      flex: 1;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start
    }

    .testWordWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .testWord {
      font-weight: 700;
      color: $greyishBrown;
      min-height: 24px;
      margin-top: 5px;
      margin-bottom: 25px;
      height: 46px;
    }

    .barWrapper {
      text-align: center;
      margin-bottom: 40px;
    }

    .answerButtonsWrapper {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .answerButtons {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .answerButton {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center
    }

    .answerButtonText {
      font-weight: 400;
      text-align: center
    }

    .dontknowButton {
      height: 50px;
      margin-top: 30px;
      font-weight: 400;
      color: $melon
    }

    .notSelected {
      border: 1px solid $tealish;
      background-color: $white;
    }

    .correctButton {
      color: $white;
      border-width: 0
    }

    .wrongButton {
      color: $white;
      border-width: 0;
    }
  }

  .leveloptions {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .offerToPass {
      font-weight: 300;
      color: $greyishBrown;
      text-align: center;
    }

    .levelsWrapper {
      margin-left: 10px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start
    }

    .levelsWrapperRow {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 10px;
    }

    .levelItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 200px;
      height: 200px;
      border-radius: 10px;
      //background-color: $white;

      img {
        transition: all 0.3s ease-out;

        &:hover {
          filter: grayscale(100%);
        }
      }
    }

    .itemImage1 {
      width: 120px;
      //height: 100px;

      margin-top: 10px;
      margin-bottom: 5px;
    }

    .itemImage {
      width: 120px;
      //height: 100px;

      margin-top: 10px;
      margin-bottom: 5px;
    }

    .levelText {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: $tealish;
    }

    .levelNumbers {
      font-size: 12px;
      font-weight: 400;
      text-align: center;
      color: $greyishBrown;
      margin-bottom: 10px;
    }

    .choiceButtonItemOnMy {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 40px;
      margin-top: 10px;
      margin-bottom: 50px;
    }

    .choiceButtonOnMyOwn {
      font-weight: 400;
      color: $waterBlue;
    }
  }

  .goodword {
    font-size: 16px;
    line-height: 1.5;
    //white-space: break-spaces;
    white-space: pre-line;
  }

  .results {
    .scores {
      font-weight: 300;
      color: $tealish;
    }

    .trainingButtonContainer {
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
    }

    .selectedWrapper {
      margin-left: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }

    .selectedLabel {
      color: $greyishBrown;
      font-weight: 300
    }

    .resultText {
      color: $greyishBrown;
      font-weight: 400;
      text-align: center;
    }

    .selectedIcon {
      margin-right: 5px;
      //color: $tealish;
    }

    .wordsCouldLearn {
      color: $melon;
      text-align: center;
    }

    .levelDescription {
      color: $greyishBrown;
      text-align: center;
      font-weight: 300;
    }

    .correctAnswers {
      color: $tealish;
      font-weight: bold;
      text-align: center;
    }

    .deleteListsTextWrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 10px;
    }

    .bulkModeText {
      color: $greyishBrown;
      font-weight: 400;
      text-align: center
    }

    .selectAllWords {
      font-weight: 400;
      text-align: center
    }

    .bulkModeWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-align: center;
    }

    .bulkButton {
      display: flex;
      width: 45%;
      justify-content: center;
      align-items: center;
      border-width: 1px;
      border-radius: 5px;
    }

    .cancelButton {
      display: flex;
      width: 45%;
      justify-content: center;
      align-items: center;
      border-width: 1px;
      border-radius: 5px;
      border-color: $greyishBrown5
    }

    .cancelButtonText {
      text-align: center;
      color: $greyishBrown;
      font-weight: 400
    }

    .bulkButtonText {
      text-align: center;
      font-weight: 400
    }
  }

  .selectwords {

    &.contentWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start
    }

    .howManyLists {
      font-weight: 400;
      margin-bottom: 15px;
      margin-top: 15px;
      color: $greyishBrown;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center
    }

    .level {
      font-weight: 700;
      margin-bottom: 15px;
      color: $tealish;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center
    }

    .totalWords {
      font-weight: 400;
      margin: 25px 15px;
      color: $greyishBrown;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center
    }

    .totalWordsRemark {
      margin: 5px 0;
      font-weight: 300;
      font-style: italic;
      color: $greyishBrown;
      padding-left: 20px;
      padding-right: 20px;
      text-align: center
    }

    .wordsWrapper {
      width: deviceWidth -30;
      margin: 10px 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      position: relative;
      height: 40px;
    }

    .choiceWrapper {
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .choiceButtonItem {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $tealish;
      height: 50px;
      border-radius: 25px;
      margin-bottom: 10px;
      width: 150px;

      &.wider {
        width: 200px;
      }
    }
  }
}

