.phrasebooks {
  min-height: calc(100vh - 180px);

  .phrasebookHeader {
    &:hover {
      cursor: pointer;
    }
  }

  .phrasebookHeaderView {
    padding-left: 10px;
    padding-right: 10px;
    background-color: white
  }
  
  .phrasebookHeaderText {
    font-weight: 700;
    color: $tealish;
  }
  
  .byTranslationWrapper {
    margin: 20px 25px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .byTranslationLabel {
    color: $greyishBrown;
    font-weight: 300
  }

  .byTranslationIcon {
    color: $tealish;
  }

  .phrasebook {
    padding-left: 30px;
    font-size: 14px;

    &:hover {
      cursor: pointer;
    }
  }
}


