.tagsfilter {

  .listName {
    font-weight: 700;
    text-align: center;
    color: $tealish;
  }

  .noTags {
    font-weight: 700;
    text-align: center;
    color: $coral;
  }

  .tagName {
    font-weight: 400;
    color: $greyishBrown;
  }

}


