.coursePage {
  min-height: calc(100vh + 180px);

  .introWrapper {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }

  .introTitle {
    //font-weight: 700;
    color: $tealish;
    margin-top: 10px;
    white-space: pre-line;
  }
  
  .introText {
    font-weight: 300;
    color: $greyishBrown;
    margin-bottom: 15px;
    text-align: center
  }
  
  .introTextMotto {
    font-weight: 700;
    color: $greyishBrown;
    margin-bottom: 15px;
  }

  .startButton {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mistakesWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 15px;
    padding: 5px;
    //border-radius: 10px;
    //background-color: $white;
  }

  .mistakesTitle {
    font-weight: 700;
    //margin-top: 10px;
    margin-bottom: 10px;
    color: $greyishBrown;
  }

  .mistakesDescription {
    font-weight: 300;
    margin-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    color: $greyishBrown;
  }

  .wordsMistakes {
    font-weight: 700;
    color: $dustyOrange;
  }

  .scores {
    color: $greyishBrown;
    font-weight: 300;
  }

  .scoresNumber {
    color: $tealish;
    font-weight: 300;
  }

  .icon {
    font-size: 25px;
  }

  .dot {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }

  .smallDot {
    width: 15px;
    height: 15px;
    border-radius: 15/2;
  }

  .tabletDot {
    width: 30px;
    height: 30px;
    border-radius: 30/2
  }

  .tabletDotMiddle {
    width: 25px;
    height: 25px;
    border-radius: 25/2
  }
}

