.everyday{
  
  .everydayheader {
    text-align: center;
    font-weight: 700;
    color: $tealish
  }

  .everydayword {
    text-align: center;
    font-weight: 400;
    color: $greyishBrown,
  }

  .everydaytranslation {
    text-align: center;
    font-weight: 400;
    font-style: italic;
    color: $greyishBrown63
  }

  .addEveryday {
    color: $greyishBrown63,
  }

}