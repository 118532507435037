.glossaryToolbar {
  .toolbarCol {
    @media (max-width: 991px) {
      &:first-child {
        display: flex;
        justify-content: center;
      }
      margin-bottom: 10px;
    }
  }
}