.glossaries {
  min-height: calc(100vh - 200px);

  .headerWrapper {
    width: 100%;
    height: 115px;
    position: relative;
  }

  .navBarTitle {
    height: 40px;
  }

  .headerImage {
    position: absolute;
    height: 105px;
    width: 100%;
  }

  .customNavBar {
    height: 40px;
    margin-top: 20px;
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 2
  }

  .filterButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-color: $tealish;
  }

  .glossaryFilterName {
    font-size: 12px!important;
  }

  .filterButtonTablet {
    //marginHorizontal: (deviceWidth - 300) / 2;
    margin-top: 15px;
    margin-bottom: 15px;

    display: flex;
    max-width: 400px;
    height: 60px;
    border-radius: 30px;
    width: 300px;
    flex-direction: row;
    align-items: center;
    justify-content: center
  };

  .parentHeader {
    display: flex;
    padding-top: 5px;
    padding-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    flex-direction: column
  }

  .headerTitle {
    margin-right: 10px;
    text-align: center;
    color: $greyishBrown;
    font-weight: 700;
  }

  .headerNumbers {
    text-align: center;
    color: $greyishBrown;
    font-weight: 300;
    font-style: italic
  };

  .description {
    text-align: center;
    font-weight: 300;
    padding-left: 10px;
    padding-right: 10px;
    font-style: italic
  }
}
