.alertnews {
  .title {
    color: $tealish;
    font-weight: 700;
    text-align: left;
  }

  .content {
    color: $greyishBrown;
    font-weight: 300;
    text-align: left;
    margin-bottom: 10px;
    white-space: pre-line;
  }

  .linkButton {
    width: 100%;
  }

  .link {
    color: $waterBlue;
    width: 100%;
    font-style: italic;
    text-align: left;
    text-decoration-style: solid;
    text-decoration-color: $waterBlue;
    text-decoration-line: underline;
  }
}