.links {

  height: 100vh;
  padding-top: 30px;
  padding-bottom: 30px;

  .link-wrapper {
    //margin-top: 30px;
    //margin-bottom: 30px;
    max-width: 400px;
    min-height: 380px;

    height: 100%;
  }

  .title {
    text-align: center;
    font-size: 40px;

    @media (max-width: 400px) {
      font-size: 30px;
    }
  }

  .description {
    text-align: center;
    font-size: 20px;

    @media (max-width: 400px) {
      font-size: 16px;
    }
  }

  .icons {
    .itunes {
      margin-right: 20px;

      @media (max-width: 400px) {
        margin-right: 0;
        margin-bottom: 20px;
      }

    }
    @media (max-width: 400px) {
      flex-direction: column;
      margin: 20px;
      align-items: center;
    }
  }
}