.landing {
  min-height: calc(100vh - 200px);

  .row {
    @media (max-width: 500px) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .pageWrapper {
    margin-top: 80px;
    @media (max-width: 530px) {
      margin-top: 120px;
    }

    .word-sources {
      position: relative;
      margin-top: 50px;
      margin-bottom: 50px;

      .description {
        width: 800px;

        margin-left: auto;
        margin-right: auto;

        @media (max-width: 800px) {
          width: 100%;
        }
      }

      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: #23B3CC;
        left: 0;
        right: 0;
        height: 70px;
        top:-50px;
        -ms-transform: rotate(1deg); /* IE 9 */
        -webkit-transform: rotate(1deg); /* Safari */
        transform: rotate(1deg);

        @media (max-width: 500px) {
          height: 60px;
          left: 0;
          right: 0;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: #23B3CC;
        left: 0;
        right: 0;
        height: 70px;
        bottom:-50px;
        -ms-transform: rotate(-1deg); /* IE 9 */
        -webkit-transform: rotate(-1deg); /* Safari */
        transform: rotate(-1deg);

        @media (max-width: 500px) {
          height: 60px;
          left: 0;
          right: 0;
        }
      }
    }
  }

  .sub-nav {
    position: fixed;
    left: 0;
    right: 0;
    top: 50px;
    background-color: white;
    z-index: 999;
    box-shadow: 2px 0 5px rgba(0, 0, 0, .2);

    a {
      &:hover {
        cursor: pointer;
      }

      &.active {
        color: $tealish;
      }
    }
  }

  img.devices {
    width: 400px;
    margin: 0 auto;
    display: block;

    @media (max-width: 450px) {
      width: 90%;

    }
  }

  .icons {
    .itunes {
      margin-right: 20px;

      @media (max-width: 400px) {
        margin-right: 0;
        margin-bottom: 20px;
      }

    }
    @media (max-width: 400px) {
      flex-direction: column;
      margin: 20px;
      align-items: center;
    }
  }

  .main-header {
    font-size: 40px;
    @media (max-width: 400px) {
      font-size: 34px;
    }
  }

  .main-sub-header {
    font-size: 26px;
    color: $tealish;
    @media (max-width: 400px) {
      font-size: 20px;
    }
  }

  .block {
    padding: 30px 50px;
    min-height: 500px;
    
    .carousel-inner {
      min-height: 432px;
    }

    ul {
      padding-left: 25px;
    }

    ul li {
      margin-bottom: 10px;
    }

    &.header-block {
      background-color: $white;

      .logo {
        width: 120px;
        height: 120px;
      }
    }

    &.small-block {
      min-height: 300px;
      background-color: $tealishTwo;
      color: $white;

      .header {
        color: $white;
        font-weight: bold;
        text-transform: uppercase;
      }

    }

    &.method-block {
      //background-color: $white;

    }

    &.dictionary-block {
      background-color: $white;

      position: relative;
      margin-top: 60px;
      margin-bottom: 60px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: white;
        left: 0;
        right: 0;
        height: 70px;
        top:-50px;
        -ms-transform: rotate(1deg); /* IE 9 */
        -webkit-transform: rotate(1deg); /* Safari */
        transform: rotate(1deg);

        @media (max-width: 500px) {
          height: 60px;
          left: 0;
          right: 0;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: white;
        left: 0;
        right: 0;
        height: 70px;
        bottom:-50px;
        -ms-transform: rotate(-1deg); /* IE 9 */
        -webkit-transform: rotate(-1deg); /* Safari */
        transform: rotate(-1deg);

        @media (max-width: 500px) {
          height: 60px;
          left: 0;
          right: 0;
        }
      }

    }

    &.training-block {
      background-color: $tealish;
      color: white;
      position: relative;

      margin-top: 80px;
      margin-bottom: 60px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: $tealish;
        left: 0;
        right: 0;
        height: 70px;
        top:-50px;
        -ms-transform: rotate(-1deg); /* IE 9 */
        -webkit-transform: rotate(-1deg); /* Safari */
        transform: rotate(-1deg);

        @media (max-width: 500px) {
          height: 60px;
          left: 0;
          right: 0;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: $tealish;
        left: 0;
        right: 0;
        height: 70px;
        bottom:-50px;
        -ms-transform: rotate(1deg); /* IE 9 */
        -webkit-transform: rotate(1deg); /* Safari */
        transform: rotate(1deg);

        @media (max-width: 500px) {
          height: 60px;
          left: 0;
          right: 0;
        }
      }

      .header {
        color: white;
      }
    }

    &.course-block {
      background-color: $white;
      //color: white;
      position: relative;
      margin-top: 20px;
      margin-bottom: 60px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: white;
        left: 0;
        right: 0;
        height: 70px;
        top:-50px;
        -ms-transform: rotate(1deg); /* IE 9 */
        -webkit-transform: rotate(1deg); /* Safari */
        transform: rotate(1deg);

        @media (max-width: 500px) {
          height: 60px;
          left: 0;
          right: 0;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: white;
        left: 0;
        right: 0;
        height: 70px;
        bottom:-50px;
        -ms-transform: rotate(-1deg); /* IE 9 */
        -webkit-transform: rotate(-1deg); /* Safari */
        transform: rotate(-1deg);

        @media (max-width: 500px) {
          height: 60px;
          left: 0;
          right: 0;
        }
      }
      .header {
        //color: white;
      }
    }

    &.lists-block {
      background-color: $greyishTeal;
      color: white;

      position: relative;
      margin-top: 60px;
      margin-bottom: 60px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: $greyishTeal;
        left: 0;
        right: 0;
        height: 70px;
        top:-50px;
        -ms-transform: rotate(1deg); /* IE 9 */
        -webkit-transform: rotate(1deg); /* Safari */
        transform: rotate(1deg);

        @media (max-width: 500px) {
          height: 60px;
          left: 0;
          right: 0;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: $greyishTeal;
        left: 0;
        right: 0;
        height: 70px;
        bottom:-50px;
        -ms-transform: rotate(-1deg); /* IE 9 */
        -webkit-transform: rotate(-1deg); /* Safari */
        transform: rotate(-1deg);

        @media (max-width: 500px) {
          height: 60px;
          left: 0;
          right: 0;
        }
      }


      .header {
        color: white;
      }
    }
    &.other-block {
      background-color: $white;
      margin-bottom: -50px;

      position: relative;
      margin-top: 60px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        background-color: white;
        left: 0;
        right: 0;
        height: 70px;
        top:-50px;
        -ms-transform: rotate(-1deg); /* IE 9 */
        -webkit-transform: rotate(-1deg); /* Safari */
        transform: rotate(-1deg);

        @media (max-width: 500px) {
          height: 60px;
          left: 0;
          right: 0;
        }
      }


      .header {
        font-size: 22px;
        text-align: left;
        margin-bottom: 15px;
      }
    }

    .header {
      text-align: center;
      color: $dustyGreen;
      margin-bottom: 30px;

      @media (max-width: 400px) {
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .description {
      font-size: 20px;
      line-height: 26px;

      @media (max-width: 400px) {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 30px;
      }
    }

    .carousel {
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    }
    .carousel-indicators {
      margin-left: 5%;
      margin-right: 5%;

      li {
        background-color: $lightGreyTwo;
        height: 5px;
      }
    }

    .carousel-caption {
      margin: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(0, 0, 0, .225);

      h3 {
        //color: $tealish;
        font-size: 20px;
      }
    }

    @media (max-width: 400px) {
      padding: 10px;
    }
  }

}


