@import "../../../styles/_variables.scss";
@import "../../../styles/_mixins.scss";

.glossaryCard {
  img {
    transition: all 0.3s ease-out;
  }
  &:hover{
    //opacity: 0.7!important;
    cursor: pointer;
    img {
      filter: grayscale(100%);
    }
  }
}

.glossaryWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: $white;

  //shadowColor: $greyishBrown;
  //shadowOpacity: 0.5;
  //shadowRadius: 3;
}

.glossaryContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  align-items: center;
  justify-content: flex-start;

}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: 10px;
}

.glossaryName {
  width: 100%;
  font-weight: 400;
  text-align: center;
  color: $tealish;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
}

.totalWords {
  font-weight: 700;
  color: $greyishBrown;
  margin-bottom: 5px;
}

.inLists {
  font-weight: 300;
  color: $greyishBrown;
  margin-bottom: 5px;
}