.accountcell {

  width: 250px;
  height: 260px;
  margin: 10px;

  .delete {
    position: absolute;
    right: 0;
    top: 0;
    color: $lightGreyThree;
    &:hover {
      color: $coral;
    }
  }

  &:hover {
    cursor: pointer;
  }

  .accountWrapper {
    //width: (deviceWidth - 50) / 2;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .accountContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: center;
    justify-content: flex-start
  }

  .accountImage {

  }
  
  .imageWrapper {
    margin-top: 10px;
    overflow: hidden;
  }

  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
  }

  .emailText {
    font-weight: 400;
    color: $tealish;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center
  }
  
  .nameText {
    font-weight: 400;
    color: $greyishBrown;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .langText {
    font-weight: 300;
    color: $greyishBrown;
    margin-bottom: 5px;
  }


}


