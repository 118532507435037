.wordmakerunlock{

  .modalView {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  .listName {
    font-weight: 700;
    text-align: center;
    color: $tealish;
  }

  .description {
    font-weight: 300;
    text-align: center;
    color: $greyishBrown;
    padding-left: 20px;
    padding-right: 20px;
  }

  .buyButton {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttonBlue {
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .buttonBlueCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .buttonText {
    font-weight: 400;
    color: $white;
  }

}