@import '~react-big-calendar/lib/sass/styles';
@import "variables.scss";

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,700");
@import '~react-redux-toastr/src/styles/index';
@import "~react-datepicker/dist/react-datepicker.css";
@import "~react-tabs/style/react-tabs.css";
@import '~react-circular-progressbar/dist/styles.css';

@import "~bootstrap/scss/bootstrap";

@import "mixins.scss";
@import "headers.scss";
@import "components.scss";
@import "bootstrap";
@import "containers.scss";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.hover {
  &:hover {
    cursor: pointer;
  }
}

body {
  font-size: 16px;
  line-height: 1.2;
  font-family: "Open Sans", sans-serif;
  background-color: $color-grey-6;
  color: $color-grey;
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }
}

a  {
  color: $waterBlue;
}

/* Change placeholder styles */
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: $color-grey-4;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: $color-grey-4;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: $color-grey-4;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: $color-grey-4;
}

input,
textarea {
  &:focus,
  &:active {
    outline: none;
  }
}

/* Change Autocomplete styles */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  border: 1px solid $color-grey-5;
  -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $color-grey;
}

.inpost-link {
  color: $color-dark-blue;
  text-decoration: none;
}

.gradient-blue {
  background-image: linear-gradient(to bottom, $tealish, $waterBlue);
  background-repeat: repeat-y;
  border: none;
  border-radius: 25px;
}
.gradient-green {
  background-image: linear-gradient(to bottom, $greyishTeal, $pine);
  background-repeat: repeat-y;
  border: none;
  border-radius: 25px;
}


a {
  &:hover {
    text-decoration: none;
  }
}


.custom-spinner {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.navbar-brand {
  @media (max-width: 400px) {
    font-size: 15px;
  }
}