.listtest {

  .contentWrapper {
    padding: 20px 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .behindImage {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .behindImageText {
    font-weight: 700;
    color: $greyishBrown
  }

  .wordText {
    font-weight: 700;
    color: $tealish;
    margin-bottom: 10px;
  }

  .answerButton {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 50px;
    border-radius: 25px;
    margin-bottom: 10px;
  }

  .answerButtonsWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

}
