.multiwordmakergameresults {
  min-height: calc(100vh - 180px);

  .text-cross {
    text-decoration: line-through;
  }

}


