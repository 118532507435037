.worddefinition{
  
  .contentWrapper {
    flex: 1;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between
  }

  .definitionScrollView {
    //width: 100%;
    max-width: 400px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
  }

  .definitionText {
    font-weight: 300;
    color: $greyishBrown;
    text-align: center;
    vertical-align: center;
    padding: 5px 15px;
  }

  .word {
    font-weight: 700;
    color: $tealish;
    text-align: center;
    vertical-align: center;
    padding: 15px;
  }

  .checkButton {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .checkButtonText {
    font-weight: 400;
    color: $tealish;
    text-align: center
  }

  .anotherDefinitionText {
    width: 100%;
    font-weight: 400;
    color: $dustyOrange;
    text-align: center
  }

  .correctButton {
    color: $white;
    border-width: 0;
    border-style:solid;
  }

  .wrongButton {
    color: $white;
    border-width: 0;
    border-style:solid;
  }

  .notSelected {
    border-color: $tealish;
    background-color: $white;
    border-width: 1px;
    border-style:solid;
  }

  .answerButtonsWrapper {
    margin-top: 15px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

  .answerButton {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    height: 50px;
    border-radius: 25px;
    margin-bottom: 10px;
  }

  .answerButtonText {
    font-weight: 400;
  }
}

