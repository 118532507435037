.subscriptionsModal {

  .introWrapper {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    white-space: pre-line;
  }

  .promo {
    text-align: center;
    font-weight: 700;
    color: $greyishBrown;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .promoDays {
    text-align: center;
    font-weight: 700;
    color: $tealish;
    margin-bottom: -10
  }

  .bottomButtons {
    width: 100%;
  }

  .introText {
    text-align: center;
    font-weight: 700;
    color: $greyishBrown;
  }

  .introDescription {
    text-align: center;
    font-weight: 300;
    margin-top: 5px;
    margin-bottom: 15px;
    color: $greyishBrown;
  }

  .description {
    text-align: center;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 10px;
    color: $greyishBrown;
  }

  .buttonText {
    font-weight: 400;
    color: $greyishBrown;
  }

  .buttonText2 {
    font-weight: 700;
    color: $greyishBrown;
  }

  .discount {
    font-weight: 300;
    color: $tealish;
  }

  .discount2 {
    font-weight: 300;
    color: $tealish;
  }

  .addButtonWrapperColumn {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
    position: relative
  }

  .addButtonWrapperColumn2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: center;
    align-items: center;
  }
}


