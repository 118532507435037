.dictionary {
  min-height: calc(100vh - 200px);

  .contentWrapper {
    flex: 1;
    margin-top: 30px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start
  }

  .listDataWrapper {
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
  }
  
  .listName {
    color: $greyishBrown;
    font-weight: 700
  }
  
  .listCapacity {
    color: $tealish;
    font-weight: 400
  }

  .byTranslationWrapper {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 25px;
    margin-right: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .byTranslationLabel {
    color: $greyishBrown;
    font-weight: 300
  }

}


