.wordinfo {
  .contentWrapper {
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .wordImageWrapper {
    position: relative;
  }

  .wordText {
    font-weight: 700;
    color: $tealish;
  }

  .transcription {
    font-weight: 300;
    color: $greyishBrown;
    margin-bottom: 15px;
  }

  .mainTranslation {
    font-weight: 400;
    color: $greyishBrown;
    margin-bottom: 10px;
  }

  .association {
    font-weight: 400;
    color: $pine;
    margin-bottom: 0;
    font-style: italic;
  }

  .mainTranslationWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
  }

  .translations {
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
  }

}


