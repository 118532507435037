// colors
$color-white: #ffffff;
$color-black: #000000;
$color-green: #88bd23;
$color-green-1: rgb(137, 189, 36);
$color-green-2: #547618;
$color-green-3: #c0de96;
$color-green-4: #b6cf6d;
$color-grey: #585858;
$color-grey-2: #7f7f7f;
$color-grey-3: #ababab;
$color-grey-4: #bfbfbf;
$color-grey-5: #e5e5e5;
$color-grey-6: #f2f2f2;
$color-grey-7: #d8d8d8;
$color-red: #d20101;
$color-red-1: #ec0000;
$color-dark-blue: #3d5894;

$white: #ffffff;
$black: #000000;
$lightGrey: #EFEFEF;
$brightgrey: #EFEFEF;
$lightGrey75: rgba(239,239,239,0.75);
$lightGrey5: rgba(239,239,239,0.5);
$lightGrey25: rgba(239,239,239,0.25);
$greyishBrown35: rgba(239,239,239,0.35);
$darkGreyBlue: #24535A;
$lightGreyTwo: #E4E4E4;
$lightGreyThree: #EBE9EB;
$greyishBrown: #3E3D3D;
$greyishBrown63: rgba(62,61,61,0.63);
$greyishBrown5: rgba(62,61,61,0.5);
$greyishBrown2: rgba(62,61,61,0.2);
$tealish: #26C1CD;
$tealishTwo: #23B3CC;
$tealishThree: #26C1CD;
$greenish: #70A56E;
$dustyGreen: #70A56E;
$greyishTeal: #6EA5A5;
$pine: #295A24;
$brandGreen: #254C53;
$waterBlue: #1A89C9;
$headerBlue: #1e9bca;
$coral: #F35757;
$darkCoral: #D04B43;
$dustyOrange: #F3724B;
$dustyOrangeTwo: #F36A4E;
$melon: #FF7B53;
$pink: #ffc3cb;
$transparent: transparent;
$golden: #f5dc34;
$grey: rgba(62, 61, 61, 0.5);

// breakpoints
$extra-small: 360px;
$small: 576px;
$medium: 768px;
$large: 992px;
$extra-large: 1200px;

// spacing
$space-large: 50px;
$space-big: 40px;
$space-medium: 30px;
$space-small: 15px;
$space-extra-small: 5px;

// fonts
$font-extra-large: 1.5625rem;
$font-large: 1.1875rem;
$font-basic: 0.9375rem;
$font-middle: 0.82rem;
$font-small: 0.75rem;

// sizes
$content-large: 1140px;
