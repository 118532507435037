.listsfilters {

  .buttonsModal {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center
  }

  .introWrapper {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid $lightGreyTwo;
  }

  .introText {
    text-align: center;
    font-weight: 400;
    color: $greyishBrown
  }

  .sortRow {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .orderButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }

  .sortButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sortButton {
    font-size: 20px;
  }

  .sortButtonTablet {
    font-size: 45px;
  }

  .filterName {
    font-weight: 400;
    color: $tealish;
  }

}


