.sharebuttons {

  .buttons {
    button {
      margin: 5px;
    }
  }
  .desc {
    font-size: 18px;
    white-space: pre-line;
  }
  .desc2 {
    font-size: 18px;
    white-space: pre-line;
  }

  .hover {
    input, label {
      &:hover {
        cursor: pointer;
      }
    }
  }
}