.auth-logo {
  min-height: calc(100vh - 180px);


  .logo-container {
    margin-top: 70px;
  }
}


.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;

  input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.register {
    input[type="email"] {
      border-radius:25px;
    }
  }

  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }

  input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.logo {
  width: 100px;
  height: 100px;
  border-radius: 50px
}

.socialIcon {
  font-size: 50px;
  color: $waterBlue;
}

.signUpLink {
  display: block;
  text-align: center;
  color: $waterBlue;
  font-weight: 400;
  font-size: 16px;
}

.forgotLink {
  float: right;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  color: $coral;
  text-align: right;

  &:hover {
    text-decoration: none;
    color: darken($coral, 20%);
  }
}