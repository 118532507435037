.calendar {

  min-height: calc(100vh - 180px);

  .subHeaderWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 20px 25px;
  }

  .pageContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .description {
    font-weight: 400;
    color: $greyishBrown;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
  }

  .arrow {
    color: $tealish
  }

  .postponeButton {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .postponeText {
    color: $waterBlue;
    text-align: center
  }

  .rbc-event-content {
    font-size: 8px;
  }

  .rbc-toolbar-label {
    text-align: right;
    font-weight: bold;
  }
}
