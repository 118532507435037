.courseList {
  margin: 5px;
  border-radius: 10px!important;

  &:hover {
    cursor: pointer;
    img {
      filter: grayscale(100%);
    }
  }

  .listWrapper {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
  }

  .listContainer {
    background-color: $white;
    border-radius: 10px;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    align-items: center;
    justify-content: flex-start
  }

  .wordImage {
    transition: all 0.3s ease-out;

    margin-left: 5px;
    margin-right: 5px;
  }
  
  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .listName {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: 700;
    text-align: center;
    color: $tealish;
    min-height: 40px;
  }
  
  .flexRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
  }
  
  .scores {
    font-weight: 300;
    color: $pine;
    margin-right: 5px;
  }
  
  .scoresNum {
    font-weight: 300;
    color: $greyishBrown;
  }

  .correct {
    font-weight: 300;
  }

  .starsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    justify-content: center;
  }

  .star {
    font-size: 18px;
    margin-left: 2px;
    margin-right: 2px;
  }
  
  .lock {
    color: $lightGreyThree;
  }
  
  .dot {
    width: 20px;
    height: 20px;
    border-radius: 10px;
  }
  
  .smallDot {
    width: 15px;
    height: 15px;
    border-radius: 15/2;
  }
  
  .tabletDot {
    width: 30px;
    height: 30px;
    border-radius: 30/2
  }
  
  .tabletDotMiddle {
    width: 25px;
    height: 25px;
    border-radius: 25/2;
  }
}


