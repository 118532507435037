.article {
  min-height: calc(100vh - 200px);

  .title {
    color: $tealish;
    font-weight: 700;
    text-align: left;
  }

  .content {
    color: $greyishBrown;
    font-weight: 300;
    text-align: left;
    margin-bottom: 10px;
    white-space: pre-line;
  }

  .linkButton {
    width: 100%;
  }

  .link {
    color: $waterBlue;
    width: 100%;
    font-style: italic;
    text-align: left;
    text-decoration-style: solid;
    text-decoration-color: $waterBlue;
    text-decoration-line: underline;
  }


  p.ql-align-center {
    text-align: center;
  }

  .my-image {
    margin: 10px;
    border-radius: 3px;
    box-shadow: 2px 2px 5px #cccccc;
    max-width: 100%;
  }

  h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  ul,ol {
    margin-top: 0;margin-bottom: 0; margin-left: 10px;

    li {
      margin-bottom: 5px;
    }
  }

  a {
    color: $headerBlue
  }
  p {
    width:100%!important;
  }

  p {
    min-height:0!important;
    margin-bottom: 0!important;
    padding-bottom: 10px;
    line-height: 20px;
  }
}
