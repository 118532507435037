.allproducts {
  min-height: calc(100vh - 200px);
  
  .discount {
    margin-right: auto;
    margin-left: auto;

    @media (max-width: 991px) {
      margin-left: auto;
    }
  }

  .discount-label {
    position: absolute;
    transform: rotate(45deg);
    background-color: $orange5;
    //left: 0;
    //right: -100px;
    min-height: 30px;
    //top: -70px;
    width: 180px;
    right: -50px;
  }

  .bought {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    //color: $greenish;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-align: center;

  }

  .buy-button {
    //position: absolute;
    //bottom: 20px;
    //width: 280px;
    width: 100%;
  }

  .promo-line {
    font-size: 14px;
  }
  .promo {
    text-align: center;
    font-weight: 700;
    color: $greyishBrown;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .promoDays {
    max-width: 500px;
    margin: 20px auto;
    text-align: center;
    //font-weight: 700;
    color: $tealish;
  }

}