.allpurchases {
  min-height: calc(100vh - 200px);

  .canceled {
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    height: 50px;
    color: $darkCoral;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    text-align: center;
    font-style: italic;
    padding-left: 15px;
    padding-right: 15px;
  }

  .cancel-button {
    //position: absolute;
    //bottom: 20px;
    //width: 280px;
    width: 100%;
  }

  .promo {
    text-align: center;
    font-weight: 700;
    color: $greyishBrown;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .promoDays {
    max-width: 500px;
    margin: 20px auto;
    text-align: center;
    font-weight: 700;
    color: $tealish;
  }

}