.wordextractor {
  
  .headerWrapper {
    width: 100%;
    height: 100px;
    position: relative
  }

  .typedWordView {
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    justify-content: center;
  }

  .typedWord {
    font-weight: 700;
    color: $tealish
  }

  .correctWordsView {
    display: flex;
    margin-top: 10px;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    align-items: flex-start;
    justify-content: flex-start
  }

  .correctWords {
    font-weight: 300;
    color: $tealish;
    text-align: center
  }

  .lettersWrapper {

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: center;
    flex-wrap: wrap;
    margin: 10px auto;
  }

  .letterView {
    border-width: 1px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;

  }

  .letter {
    font-weight: 700;
  }
}
