.userbadge {

  &:hover {
    cursor: pointer;

    img {
      filter: grayscale(80%);
    }
  }

  .badgeWrapper {
    position: relative;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .background {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center
  }

  .badgeText {
    font-weight: 300;
    text-align: center;
    bottom: 0;
  }

  .imageBadge {}

}

